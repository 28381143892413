import React from 'react'
import "./IGallery_card.css"
import { motion } from 'framer-motion';

const IGallery_card = (props) => {
    const redirectToLink = () => {
        window.open(props.link, '_blank');
    }
  return (
    <motion.div
      whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
      whileHover={{ scale: [null, 1.05, 1.05] }}
      transition={{duration: 0.5}}
      className='IG-content'
    >
      <img className="IG-content-img" src={require('../../Assets/IGallery/' + props.img + '.svg')} alt="gambar" />
      <h1 className='IG-content-title'>{props.title}</h1>
      <h1 className='IG-content-category'>{props.category}</h1>
      <p className='IG-content-desc'>{props.desc}</p>
      <h1 className='IG-content-created'>Created by <span className='createdby'>{props.created}</span></h1>
      <button className='IG-button' onClick={redirectToLink} ><span>See Project {'>'}</span></button>
    </motion.div>
  )
}

export default IGallery_card