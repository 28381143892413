export default [
    {
        id:1,
        title:"Arcanon",
        category:"Rekayasa Perangkat Lunak",
        desc:`The development of the Arcanon software is
        expected to improve mental health, facilitate positive
        social interactions, and enhance the overall quality of 
        life for individuals and society as a whole.`,
        created:"Ipan, Ismy, Zefan KOM 58",
        img:"Arcanon",
        link:"https://github.com/irfanalmsyah/arcanon"
    },
    {
        id:2,
        title:"BibitQ",
        category:"Basis Data",
        desc:`BibitQ is a web-based platform that offers a wide variety of plant seeds for sale. Our platform allows users to easily browse and purchase seeds from a variety of different plant species.`,
        created:"Giras, Fahmi, Tion KOM 58",
        img:"BibitQ",
        link:"https://github.com/GirasArya/BibitQ"
    },
    {
        id:3,
        title:"Boekber",
        category:"Rekayasa Perangkat Lunak",
        desc:`BoekBer is an online haircut service ordering platform for customers who want to get their hair done without having to leave the house.`,
        created:"Giras, Andhika, Bima KOM 58",
        img:"Boekber",
        link:"https://github.com/GirasArya/BoekBer"
    },
    {
        id:4,
        title:"Brain Health",
        category:"Basis Data",
        desc:`BrainHealth adalah sebuah aplikasi berbasis web yang digunakan oleh user/pasien untuk melakukan pemesanan jadwal konsultasi secara daring dengan psikolog.`,
        created:"Mirza, Khalil, Cilla KOM 58",
        img:"Brain_Health",
        link:"https://github.com/mirzahm14/BrainHealth"
    },
    {
        id:5,
        title:"Covfefe",
        category:"Grafika Komputer",
        desc:`Proyek mata kuliah Grafika Komputer dan Visualisasi. Peta choropleth yang menampilkan data produktivitas kopi menurut provinsi di Indonesia, 2014-2021.`,
        created:"Ipan, Hariz, Gopal, Aam 58",
        img:"Covfefe",
        link:"https://github.com/irfanalmsyah/covfefe"
    },
    {
        id:6,
        title:"Cyroom",
        category:"Rekayasa Perangkat Lunak",
        desc:`Cyroom adalah sebuah website yang dibuat untuk pertukaran informasi dimana pengguna dapat mengajukan pertanyaan dan menjawab pertanyaan yang berkaitan dengan teknologi.`,
        created:"Shafa, Nechita, Novia, Iant KOM 58",
        img:"Cyroom",
        link:"https://github.com/kelapacuyy/cyroom"
    },
    {
        id:7,
        title:"IPB Log",
        category:"Rekayasa Perangkat Lunak",
        desc:`IPBLog web Interaktif Mahasiswa IPB: Pusat Informasi Seputar Kampus dan Mahasiswa.`,
        created:"Rafif, Faza, Hariz KOM 58",
        img:"IPBLog",
        link:"https://github.com/generasirabbani/ipb-log"
    },
    {
        id:8,
        title:"KomGallery",
        category:"Rekayasa Perangkat Lunak",
        desc:`KomGallery is a social media application designed specifically for the Computer Science students at IPB University.`,
        created:"Ayyas, Dinda, Atul KOM 58",
        img:"KomGallery",
        link:"https://github.com/ayyasmumtaz/Komgallery"
    },
    {
        id:9,
        title:"MyLaundry",
        category:"Rekayasa Perangkat Lunak",
        desc:`MyLaundry merupakan sebuah projek tugas akhir yang bertujuan untuk membantu pengguna dalam mengelola cucian mereka dengan lebih mudah dan efisien.`,
        created:"Kotop, Surya, Jihan KOM 58",
        img:"MyLaundry",
        link:"https://github.com/Abrumz/MyLaundry"
    },
    {
        id:10,
        title:"Noted",
        category:"Rekayasa Perangkat Lunak",
        desc:`Proyek mata kuliah RPL semester 4. Website pencatatan keuangan dan penyimpanan catatan.`,
        created:"Zahran, Salma, Okta KOM 58",
        img:"Noted",
        link:"https://github.com/zran147/Noted"
    },
    {
        id:11,
        title:"Presensi Komunitas",
        category:"Basis Data",
        desc:`Presensi Komunitas adalah website yang dapat mempermudah proses presensi oleh anggota komunitas serta mempermudah para PJ komunitas dalam melakukan arsip kehadiran secara efektif.`,
        created:"Andika, Ferdinand, Raisya KOM 58",
        img:"Presensi_Komunitas",
        link:"https://github.com/andikarisky28/Website_Presensi"
    },
    {
        id:12,
        title:"PunnyPals",
        category:"Basis Data",
        desc:`Intended for students who want to focus more on learning pronunciation and intonation. `,
        created:"Adi, Jesslyn, Ifdha KOM 58",
        img:"PunnyPals",
        link:"https://github.com/Adsattt/PunnyPals"
    },
    {
        id:13,
        title:"Sotory IPB",
        category:"Desain Pengalaman Pengguna",
        desc:`Sotory IPB : Find Unique Fact About IPB`,
        created:"Raka, Irfan, Fitri KOM 58",
        img:"Sotory_IPB",
        link:"https://www.figma.com/proto/xPplP65yDWRxC4AaSdOYHC/Prototype---Sotory-IPB?page-id=70%3A3206&type=design&node-id=130-3906&viewport=687%2C846%2C0.49&scaling=scale-down&starting-point-node-id=237%3A3695&show-proto-sidebar=1"
    },
    {
        title:"Touch Screen Device IPB Museum",
        category:"Desain Pengalaman Pengguna",
        desc:`Redesign layar touchscreen pada museum IPB.`,
        created:"Andhika, Giras, Salma, Yoga KOM 58",
        img:"Touch_Screen_Device_IPB_Museum",
        link:"https://github.com/lazuadik/DPP_Kelompok-5_TouchScreenDevice"
    },
    {
        title:"Touch Screen Device IPB Museum",
        category:"Desain Pengalaman Pengguna",
        desc:`Redesign layar touchscreen pada museum IPB..`,
        created:"Rafif, Salim, Khalid, Ferdinan KOM 58",
        img:"Touch_Screen_Device_IPB_Museum2",
        link:"https://www.figma.com/proto/CRWzK3JbvW9DXTIhcGzhY4/Redesign-Layar-Touchscreen?type=design&node-id=100-3372&scaling=scale-down&page-id=1%3A19&starting-point-node-id=100%3A3511&mode=design"
    },
]