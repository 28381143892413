export default [
    
    {
        id:1,
        title:"Syntax Edisi I",
        date:"2015",
        img:"Syntax Edisi I",
        link: "https://drive.google.com/file/d/1_KrveGH2xMaeV2CyDpur48_wapmTJiVQ/view?usp=drive_link"
    },
    {
        id:2,
        title:"Syntax Edisi II",
        date:"2016",
        img:"Syntax Edisi II",
        link:"https://drive.google.com/file/d/1swVqAllpHAcmH5Gc7hzJU1HEmCylKhCs/view?usp=drive_link"
    },
    {
        id:3,
        title:"Syntax Edisi III",
        date:"2017",
        img:"Syntax Edisi III",
        link:"https://drive.google.com/file/d/1EgQEU4IpxCy8kKpmu7299LPS8i9Og4ds/view?usp=drive_link"
    },
    {
        id:4,
        title:"Syntax Edisi IV",
        date:"2018",
        img:"Syntax Edisi IV",
        link: "https://drive.google.com/file/d/11-CFf4g-GaJAN4s9EiSSdZ_AuJL7W9vm/view?usp=drive_link"
    },
    {
        id:5,
        title:"Syntax Edisi V",
        date:"2019",
        img:"Syntax Edisi V",
        link:"https://drive.google.com/file/d/1LV7-lMPG0Lrggmc4z3tOwi0n7qE55n-d/view?usp=drive_link"
    },
    {
        id:6,
        title:"Syntax Edisi VI",
        date:"2020",
        img:"Syntax Edisi VI",
        link:"https://drive.google.com/file/d/1iML5GyRYzrg4SdysdsUdufwK8NW5fwD8/view?usp=drive_link"
    },
    {
        id:7,
        title:"Syntax Edisi VII",
        date:"2021",
        img:"Syntax Edisi VII",
        link:"https://drive.google.com/file/d/19-zAy-OhCAkJkYECXCV8iV53bEEmtPfz/view?usp=drive_link"
    },
    {
        id:8,
        title:"Syntax Edisi VIII",
        date:"2022",
        img:"Syntax Edisi VIII",
        link:"https://drive.google.com/file/d/1fM0vDEHRsIdOKNj9J2N6ukHHxRk4wxqJ/view?usp=drive_link"

    },
    {
        id:9,
        title:"Syntax Edisi IX",
        date:"2023",
        img:"Syntax Edisi IX",
        link:"https://drive.google.com/file/d/1rx8XHkYN9cAE4AqtPTY05gQHhB7niNJ5/view?usp=drive_link"

    },

]